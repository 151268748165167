import React, { FC } from 'react'
import useForm from '@hooks/useForm'
import { TFormUser } from '@components/Form/FormUser'
import GenericFormPage from '@templates/Generic/Form'
import { Button } from 'gatsby-material-ui-components'
import { ApiResponse, getUser, updateUser, UserResponse } from '@utils/api'
import { Grid, Stack } from '@mui/material'
import useApi from '@hooks/useApi'
import { PageProps } from 'gatsby'
import { TextFieldPassword } from '@components'

const SettingsUsersResetPage: FC<PageProps> = ({ params }) => {
  const api = useApi<UserResponse>({
    apiMethod: getUser,
    params: params.username
  })

  const updateApi = useApi<ApiResponse<string>>({
    apiMethod: updateUser,
    requestOnMount: false
  })

  const initialValues = {
    username: params ? params.username : '',
    password: '',
    reenter: ''
  }

  const passwordForm = useForm<TFormUser>({
    initialValues,
    initialValidationRules: {
      password: (value, { reenter }) =>
        !!value && value.length > 11 && value === reenter,
      reenter: (value, { password }) => !!value && value === password
    }
  })

  const hasChanged =
    JSON.stringify(initialValues) !== JSON.stringify(passwordForm.values)

  return (
    <GenericFormPage
      title={`Reset Password: ${params.username}`}
      breadcrumbs={[
        {
          title: 'Settings',
          to: '/settings/'
        },
        {
          title: 'Users Management',
          to: '/settings/users/'
        },
        {
          title: 'Reset Password'
        }
      ]}
      form={passwordForm as any}
      api={updateApi}
      successMessage="Password successfully updated"
      returnPath="/settings/users/"
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={api.loading || !hasChanged}
            >
              Update User
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'back'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Stack spacing={3}>
        <TextFieldPassword
          id="password"
          label="Password"
          form={passwordForm}
          required
          helperText="Password needs to be at least 12 characters long"
        />
        <TextFieldPassword
          id="reenter"
          label="Re-enter Password"
          form={passwordForm}
          required
          helperText="Passwords must match"
        />
      </Stack>
    </GenericFormPage>
  )
}

export default SettingsUsersResetPage
